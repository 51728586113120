import React from 'react';

import Layout from '../marketing/landing/components/common/layout/layout';
import { isSalonzone } from '../utils';
import messages from '../data/messages/xx';
import salonzoneMessages from '../data/messages/salonzone/xx';

const IndexPage = ({ location }) => (
  <Layout
    location={location}
    locale="xx"
    i18nMessages={isSalonzone ? salonzoneMessages : messages}
  />
);

export default IndexPage;
